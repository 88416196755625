<template>
  <div>
    <Tab :list="tabs"></Tab>

    <router-view></router-view>
  </div>
</template>
<script>
import Tab from "@/components/Tab";

export default {
  components: {
    Tab,
  },
  data() {
    return {
      tabs: [
        {
          title: "雨量趋势分析",
          path: "/drainage/analysis/rainAmountTrend",
        },
        {
          title: "水位趋势分析",
          path: "/drainage/analysis/waterLevelTrend",
        },
      ],
    };
  },

  methods: {},
};
</script>
